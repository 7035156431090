import React, { useState, useEffect } from 'react';
import '../../assets/scss/Donation.scss';
import { Button, Image, Modal, Row, Col, Spinner } from 'react-bootstrap';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import PayPal from '../../assets/img/paypal.png'
import Bank from '../../assets/img/bank.png'
import { jwtDecode } from 'jwt-decode';
import { serverUrl } from '../../serverUrl';
import { toast } from 'react-toastify';
import Cash from '../../assets/img/money.png'
import Mpesa from '../../assets/img/mpesa.jpeg'
import Cheque from '../../assets/img/cheque.png'
import Credit from '../../assets/img/credit-card.png'
import SideBar from '../components/Sidebar';
import Header from '../components/Header';
import Whatsapp from '../../assets/img/whatsapp.png'
import Facebook from '../../assets/img/facebook.png'
import Twitter from '../../assets/img/twitter.png'
import Instagram from '../../assets/img/instagram.png'
import Paperclip from '../../assets/img/paperclip.png'
import Mail from '../../assets/img/gmail.png'
import Select from 'react-select'

function Donation() {
    const { id } = useParams()
    const accessToken = localStorage.getItem('authTokens');
    // get the access token
    const navigate = useNavigate()
    const [payLoading, setPayloading] = useState(false)
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` },
    };
    // decoding the token to get the user id
    const decodedToken = jwtDecode(accessToken);
    const userId = decodedToken.user_id;
    const [isLoading, setIsLoading] = useState(false);


    const [pledgeStatement, setPledgeStatements] = useState([])
    const [contributorStatement, setContributorStatements] = useState([])
    const [campaignStatement, setCampaignStatements] = useState({})
    const [userChurch, setUserChurch] = useState({})
    const [fullName, setFullName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [userPhoneNumber, setUserPhoneNumber] = useState('');
    const [phoneNumberOther, setPhoneNumberOther] = useState([])

    const [chequeData, setChequeData] = useState({
        chequeNumber: "",
        currency: "KSH",
        transaction_date: "",
        amount: "",
    })
    const [inKindData, setInkindData] = useState({
        description: "",


    })

    const banks = [
        {

            "name": "KCB"
        },
        {

            "name": "Standard Chartered"
        },
        {

            "name": "ABSA"
        },
        {

            "name": "NCBA"
        },
        {

            "name": "Prime Bank"
        },
        {

            "name": "Coop Bank"
        },
        {

            "name": "NBK"
        },
        {

            "name": "M-Oriental"
        },
        {

            "name": "Citi Bank"
        },
        {

            "name": "Middle East Bank"
        },
        {

            "name": "Bank of Africa"
        },
        {

            "name": "Consolidated Bank"
        },
        {

            "name": "Credit Bank"
        },
        {

            "name": "Stanbic Bank"
        },
        {

            "name": "ABC Bank"
        },
        {

            "name": "Ecobank"
        },
        {

            "name": "Paramount"
        },
        {

            "name": "Kingdom Bank"
        },
        {

            "name": "GT Bank"
        },
        {

            "name": "Victoria Bank"
        },
        {

            "name": "Guardian Bank"
        },
        {

            "name": "I&M Bank"
        },
        {

            "name": "Housing Finance"
        },
        {

            "name": "DTB"
        },
        {

            "name": "Mayfair Bank"
        },
        {

            "name": "Sidian Bank"
        },
        {

            "name": "Equity Bank"
        },
        {

            "name": "Family Bank"
        },
        {

            "name": "Gulf African Bank"
        },
        {
            "name": "Premier Bank Kenya"
        },
        {

            "name": "Dubai Islamic Bank"
        },
        {

            "name": "UBA"
        },
        {

            "name": "KWFT"
        },
        {

            "name": "Stima Sacco"
        },
        {

            "name": "Telkom Kenya"
        },
        {

            "name": "Choice Microfinance Bank"
        },
        {

            "name": "Bank of Baroda"
        }

    ]

    const [selectedBank, setSelectedBank] = useState(null);

    const optionList = banks.map(bank => ({
        value: bank.name,
        label: bank.name
    }));

    const handleSelectChange = selectedOption => {
        setSelectedBank(selectedOption);
    };

    const handlePledgeInput = (e) => {
        const { name, value } = e.target;

        setChequeData({
            ...chequeData,
            [name]: value,
        });

        setInkindData({
            ...inKindData,
            [name]: value,
        });
    };
    useEffect(() => {
        if (accessToken) {

            // serverUrl.get(`/pledge/pledge-campaign-statement-list/?payment_code=${id}`, config)
            //     .then((res) => {
            //         setPledgeStatements(res.data.results[0].pledge_statement)
            //         setContributorStatements(res.data.results[0].contributor)
            //         setCampaignStatements(res.data.results[0].campaign_data[0])
            //     })
            //     .catch((error) => {
            //         console.log(error)
            //     })

            // get the pledge data 
            serverUrl.get(`/pledge/active-pledges/?user_id=${userId}`, config)
                .then((res) => {
                    // console.log(res.data)
                })
                .catch((error) => {
                    console.log(error)
                })

            serverUrl
                .get(`/user/user-details/?id=${userId}`, config)
                .then((res) => {
                    // get the full name of the user
                    setFullName(res.data.results[0].full_name);
                    setPhoneNumber(res.data.results[0].phone_number);
                    setUserPhoneNumber(res.data.results[0].phone_number)

                })
                .catch((error) => {
                    console.log(error);
                });

            // get the other phone numbers
            serverUrl.get(`/user/user-linked-phone-numbers?user=${userId}`, config)
                .then((res) => {
                    setPhoneNumberOther(res.data.results)
                })
                .catch((error) => {
                    console.log(error)
                })

            serverUrl.get(`/churches/church-users-logo-name/?user_id=${userId}`)
                .then((res) => {
                    setUserChurch(res.data.results[0])
                })
                .catch((error) => {
                    console.log(error)
                })
        }

        // get the church details of user

    }, [id])
    const [images, setImages] = useState([]);

    const handleChangeImage = (e) => {
        const file = e.target.files[0];
        setImages(file);

    };
    const [modalShow, setModalShow] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('MPESA');

    const handleOpenRedeemModal = () => {
        setModalShow(true);

    }
    const handleShowModal = (paymentMethod) => {
        setSelectedPaymentMethod(paymentMethod);
    };

    const handleCloseModal = () => {
        setModalShow(false);
        setSelectedPaymentMethod('');
    };
    const [donationAmount, setDonationAmount] = useState(0);
    const [activeIndex, setActiveIndex] = useState(null);
    const [bankTransferOption, setBankTransferOption] = useState('PESALINK');
    const handleButtonClick = (index, amount) => {
        setActiveIndex(index);
        setDonationAmount(amount)
    };
    const handleAmountChange = (event) => {
        setAmount(event.target.value);
    };

    const handleBankTransferOptionChange = (option) => {
        setBankTransferOption(option);
    };

    const handleBankTransferSelect = (option) => {
        handleBankTransferOptionChange(option);
        setSelectedPaymentMethod(option);
    };

    // get post the data for the redeemption
    const contributor = localStorage.getItem('contributor')

    function handleRedeemAdd(e) {
        const description = `id-${campaignStatement.campaign_id}-${id}`
        e.preventDefault()
        
        if (selectedPaymentMethod === 'MPESA') {
            let formattedPhoneNumber = phoneNumber.trim();
            if (formattedPhoneNumber.startsWith('0')) {
                formattedPhoneNumber = '+254' + formattedPhoneNumber.slice(1);
            }
            if (!formattedPhoneNumber.startsWith('+254')) {
                formattedPhoneNumber = '+254' + formattedPhoneNumber;
            }

            const data = {
                amount: donationAmount,
                phone_number: formattedPhoneNumber,
                reference: id,
                description: description,
                source: "web",
                updated_by: userId,
            }

            if (userChurch) {
                data.church = userChurch.id;
            }
            if (!phoneNumber || !donationAmount) {
                // Toast an error message if any of the fields are missing
                toast.error("Please fill in all required fields.");
                return;
            }
            setPayloading(true)
            serverUrl.post(`giving/checkout/`, data, config)
                .then((res) => {
                    setSelectedPaymentMethod(null);
                    toast.success('An mpesa prompt has been sent to your phone enter pin to make payment');
                    // Reload the page after 8 seconds
                    setPayloading(false)

                    setTimeout(() => {
                        navigate(`/`)
                    }, 8000);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        else if (selectedPaymentMethod === 'CHEQUE') {
            let transactionDate = null; // Initialize transaction date to null

            if (chequeData && chequeData.transaction_date) {
                // If transaction date exists in chequeData
                transactionDate = new Date(chequeData.transaction_date); // Use the provided transaction date
            } else {
                // If transaction date is null, use the current date
                transactionDate = new Date(); // Current date
            }

            // Format the date to YYYYMMDD format
            const formattedDate = transactionDate.toISOString().slice(0, 10).replace(/-/g, '');


            const splitName = fullName.split(' ')
            const firstName = splitName[0];
            const lastName = splitName[splitName.length - 1];
            const middleName = splitName.length > 2 ? splitName.slice(1, -1).join(" ") : null;
            if (!chequeData.chequeNumber || !donationAmount || !chequeData.transaction_date) {
                toast.error('Please fill in all the required fields')
                return
            }
            const formData = new FormData();
            formData.append("payment_code", id);
            formData.append("cheque_number", chequeData.chequeNumber);
            formData.append("currency", chequeData.currency);
            formData.append("transcation_amount", parseInt(donationAmount));
            formData.append("transaction_date", chequeData.transaction_date);
            formData.append("transcation_time", formattedDate + '000000');
            formData.append("payment_channel", "cheque");
            formData.append("phone_number", phoneNumber);
            formData.append("status", "pending");
            formData.append("updated_by", userId);
            formData.append("source", "web");
            formData.append("first_name", firstName);
            formData.append("last_name", lastName);
            formData.append("middle_name", middleName);
            formData.append("bill_ref_number", id);


            // Check if cheque image is present
            if (images) {
                formData.append("cheque_image", images);
            }
            if (userChurch) {
                formData.append("church", userChurch.id);
            }
            setPayloading(true)

            serverUrl.post(`/giving/create-ctb-transaction/`, formData)
                .then((res) => {
                    toast.success('Succesfully made the contribution. Kindly wait for the admin to approve and confim the transaction. ')
                    setModalShow(false);
                    setTimeout(() => {
                        navigate(`/`)
                    }, 1000);
                    // serverUrl.get(`/pledge/pledge-campaign-statement-list/?payment_code=${id}`, config)
                    //     .then((res) => {
                    //         setPledgeStatements(res.data.results[0].pledge_statement)
                    //         setContributorStatements(res.data.results[0].contributor)
                    //         setCampaignStatements(res.data.results[0].campaign_data[0])
                    //         setPayloading(false)

                    //         setTimeout(() => {
                    //             navigate(`/pledge/${id}`)
                    //         }, 1000);
                    //     })
                    //     .catch((error) => {
                    //         console.log(error)
                    //     })
                })
                .catch((error) => {
                    console.log(error)
                })
        }

        else if (selectedPaymentMethod === 'CARD') {
            let transactionDate = null; // Initialize transaction date to null

            if (chequeData && chequeData.transaction_date) {
                // If transaction date exists in chequeData
                transactionDate = new Date(chequeData.transaction_date); // Use the provided transaction date
            } else {
                // If transaction date is null, use the current date
                transactionDate = new Date(); // Current date
            }

            // Format the date to YYYYMMDD format
            const formattedDate = transactionDate.toISOString().slice(0, 10).replace(/-/g, '');


            const splitName = fullName.split(' ')
            const firstName = splitName[0];
            const lastName = splitName[splitName.length - 1];
            const middleName = splitName.length > 2 ? splitName.slice(1, -1).join(" ") : null;

            const formData = new FormData();
            formData.append("payment_code", id);
            formData.append("transcation_id", chequeData.chequeNumber);
            formData.append("currency", chequeData.currency);
            formData.append("transcation_amount", parseInt(donationAmount));
            formData.append("transaction_date", chequeData.transaction_date);
            formData.append("transcation_time", formattedDate + '000000');
            formData.append("payment_channel", "card");
            formData.append("phone_number", phoneNumber);
            formData.append("status", "pending");
            formData.append("updated_by", userId);
            formData.append("source", "web");
            formData.append("first_name", firstName);
            formData.append("last_name", lastName);
            formData.append("middle_name", middleName);
            formData.append("bill_ref_number", id);
            if (!chequeData.chequeNumber || !donationAmount || !chequeData.transaction_date) {
                toast.error('Please fill in all the required fields')
                return
            }
            // Check if cheque image is present
            if (images) {
                formData.append("cheque_image", images);
            }
            if (userChurch) {
                formData.append("church", userChurch.id);
            }
            setPayloading(true)

            serverUrl.post(`/giving/create-ctb-transaction/`, formData)
                .then((res) => {
                    toast.success('Succesfully made the contribution. Kindly wait for the admin to approve and confim the transaction. ')
                    setModalShow(false);
                    setTimeout(() => {
                        navigate(`/`)
                    }, 1000);
                    // serverUrl.get(`/pledge/pledge-campaign-statement-list/?payment_code=${id}`, config)
                    //     .then((res) => {
                    //         setPledgeStatements(res.data.results[0].pledge_statement)
                    //         setContributorStatements(res.data.results[0].contributor)
                    //         setCampaignStatements(res.data.results[0].campaign_data[0])
                    //         setPayloading(false)

                    //         setTimeout(() => {
                    //             navigate(`/pledge/${id}`)
                    //         }, 1000);
                    //     })
                    //     .catch((error) => {
                    //         console.log(error)
                    //     })
                })
                .catch((error) => {
                    console.log(error)
                })
        }

        else if (selectedPaymentMethod === 'BANK TRANSFER') {
            const formattedDate = chequeData.transaction_date.replace(/-/g, '');
            const splitName = fullName.split(' ')
            const firstName = splitName[0];
            const lastName = splitName[splitName.length - 1];
            const middleName = splitName.length > 2 ? splitName.slice(1, -1).join(" ") : null;

            const bankData = {
                payment_code: id,
                bank_payment_channel: bankTransferOption,
                bank_transaction_reference: chequeData.chequeNumber,
                currency: chequeData.currency,
                transcation_amount: donationAmount,
                transaction_date: chequeData.transaction_date,
                transcation_time: formattedDate + '000000',
                payment_channel: "bank",
                phone_number: phoneNumber,
                status: "pending",
                updated_by: userId,
                source: "web",
                first_name: firstName,
                last_name: lastName,
                bill_ref_number: id,

            };
            if (selectedBank) {
                bankData.bank = selectedBank.value
            }
            else {
                toast.error('Bank should not be null')
                return
            }
            if (!chequeData.chequeNumber || !donationAmount || !selectedBank.value || !chequeData.transaction_date | !bankTransferOption) {
                toast.error('Please fill in all the required fields')
                return
            }

            if (middleName) {
                bankData.middle_name = middleName

            }
            setPayloading(true)

            serverUrl.post(`/giving/create-ctb-transaction/`, bankData)
                .then((res) => {
                    toast.success('Succesfully made the contribution. Kindly wait for the admin to approve and confim the transaction. ')
                    setModalShow(false);
                    setTimeout(() => {
                        navigate(`/`)
                    }, 1000);
                    // serverUrl.get(`/pledge/pledge-campaign-statement-list/?payment_code=${id}`, config)
                    //     .then((res) => {
                    //         setPledgeStatements(res.data.results[0].pledge_statement)
                    //         setContributorStatements(res.data.results[0].contributor)
                    //         setCampaignStatements(res.data.results[0].campaign_data[0])
                    //         setPayloading(false)

                    //         setTimeout(() => {
                    //             navigate(`/pledge/${id}`)
                    //         }, 1000);
                    //     })
                    //     .catch((error) => {
                    //         console.log(error)
                    //     })
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        // if (campaignStatement.pledge_type === 'monetary') {

        // }
        // else {
        //     const data = {
        //         pledge: campaignStatement.pledge_id,
        //         status: "pending",
        //         value: donationAmount,
        //         description: inKindData.description
        //     }
        //     serverUrl.post(`/giving/in-kind-donations/`, data, config)
        //         .then((res) => {

        //             toast.success('Succesfully made the contribution. Kindly wait for the admin to approve and confim the transaction. ')
        //             setModalShow(false);
        //             setPayloading(true)
        //             setTimeout(() => {
        //                 navigate(`/`)
        //             }, 1000);

        //             // serverUrl.get(`/pledge/pledge-campaign-statement-list/?payment_code=${id}`, config)
        //             //     .then((res) => {
        //             //         console.log(res.data.results)
        //             //         setPledgeStatements(res.data.results[0].pledge_statement)
        //             //         setContributorStatements(res.data.results[0].contributor)
        //             //         setCampaignStatements(res.data.results[0].campaign_data[0])
        //             //         setPayloading(true)

        //             //         setTimeout(() => {
        //             //             navigate(`/`)
        //             //         }, 1000);
        //             //     })
        //             //     .catch((error) => {
        //             //         console.log(error)
        //             //     })
        //         })
        //         .catch((error) => {
        //             console.log(erro)
        //         })

        // }



    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [copied, setCopied] = useState(false);

    const url = `https://mypledge.capstonefaithministry.com/#/shared-pledge/${id}`;

    const handleCopyLink = () => {
        const textToCopy = `${url}`;
        const tempInput = document.createElement("input");
        document.body.appendChild(tempInput);
        tempInput.value = textToCopy;
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        setCopied(true);
        toast.success("Linked is copied");
    };

    const websiteUrl = `https://mypledge.capstonefaithministry.com/#/shared-pledge/${id}`;
    const appUrl = "https://play.google.com/store/apps/details?id=com.usalamatechnology.capstone_faith_ministry";
    const descriptionNew = 'I welcome you to contribute towards my pledge for  Capstone Faith Ministry initiative. The campaign is to raise funds/resources to facilitate the settling of 23 assemblies that are in tents while others are on leased premises.'

    const messageLink = `${descriptionNew}\nTo Assist In My Pledge Click The Link below\n${websiteUrl}`;


    const whatsappLink = `https://web.whatsapp.com/send?text=${encodeURIComponent(
        messageLink
    )}`;

    const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        messageLink
    )}`;

    const EmailLink = `mailto:recipient@example.com?subject=Your%20Subject&body=${messageLink}`;

    const twitterLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        messageLink
    )}`;

    const instagramLink = "https://www.instagram.com/direct/inbox/";

    function shareFacebook() {
        let url = encodeURIComponent(messageLink);
        let title = "title";
        window.open(
            `http://www.facebook.com/sharer.php?u=${url}`,
            "pop up name",
            "width=600, height=600"
        );
    }

    const handlePlatform = (platform) => {
        var Platform = platform;

        if (platform === "whatsapp") {
            window.open(whatsappLink, "_blank");
        } else if (platform === "facebook") {
            // Handle Facebook logic here
            // ...
        } else if (platform === "gmail") {
            window.open(EmailLink);
        } else if (platform === "x") {
            window.open(twitterLink, "_blank");
        } else if (platform === "instagram") {
            window.open(instagramLink, "_blank");
        } else if (platform === "copy_link") {
            handleCopyLink();
        }

    };

    const [showOptions, setShowOptions] = useState(false);

    // Function to handle showing the options
    const handleShowOptions = () => {
        setShowOptions(true);
    };

    // Function to handle selecting a phone number from the options
    const handleSelectPhoneNumber = (selectedNumber) => {
        setPhoneNumber(selectedNumber);
        setShowOptions(false);
    };
    // Inside your component
    const [showModal, setShowModal] = useState(false);

    // Function to handle showing the modal
    const handleShowModalNumber = () => {
        setShowModal(true);
    };
    // JSX code for the options
    const phoneNumberOptions = phoneNumberOther.map((number, index) => (
        <option key={index} value={number.phone_number}>{number.phone_number}</option>
    ));

    // Function to handle adding the number
    const handleAddNumber = () => {
        const data = {
            user: userId,
            phone_number: addedPhone,
            country_name: 'Kenya',
            country_code: '+254'
        }
        setIsLoading(true)
        serverUrl.post('/user/user-linked-phone-numbers/', data, config)
            .then((res) => {
                toast.success('Succesfully added the number')
                serverUrl.get(`/user/user-linked-phone-numbers?user=${userId}`, config)
                    .then((res) => {
                        setPhoneNumberOther(res.data.results)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                setShowModal(false);
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error)
                setIsLoading(false)
            })

    };

    return (
        <>
            <SideBar />
            <Header />

            <div className='donation'>

            <>
                        <h6 style={{ textTransform: "capitalize", placeContent: "center", display: "flex" }}>{campaignStatement.campaign_description}</h6>
                        <p style={{ fontSize: "14px", fontWeight: "600", marginBottom: "0.5rem", textAlign: "left" }}>Select Mode Of Payment</p>

                      
                        <div className='d-flex flex-wrap' style={{ alignItems: "center" }}>

                            <div className='mt-2 d-flex '>
                                <div className={'ModeofPayment' + (selectedPaymentMethod === 'MPESA' ? ' selectedPayment' : '')} onClick={() => handleShowModal('MPESA')}>
                                    <Image src={Mpesa} />
                                    <p>MPESA</p>
                                </div>

                                <div className={'ModeofPayment' + (selectedPaymentMethod === 'CHEQUE' ? ' selectedPayment' : '')} onClick={() => handleShowModal('CHEQUE')}>
                                    <Image src={Cheque} />
                                    <p>CHEQUE</p>
                                </div>

                                <div className={'ModeofPayment' + (selectedPaymentMethod === 'CARD' ? ' selectedPayment' : '')} onClick={() => handleShowModal('CARD')}>
                                    <Image src={Credit} />
                                    <p>CARD</p>
                                </div>

                            </div>

                            <div className='mt-2 d-flex '>

                               
                                <div className={'ModeofPayment w-50 bankTransfer' + (selectedPaymentMethod === 'BANK TRANSFER' ? ' selectedPayment' : '')} onClick={() => handleShowModal('BANK TRANSFER')}>
                                    <Image src={Bank} tyle={{ width: "10%" }} />
                                    <p>BANK TRANSFER</p>
                                </div>

                            </div>


                        </div>

                        {selectedPaymentMethod === 'MPESA' && (
                            // Content for MPESA
                            <>

                                <div className='donation p-2'>
                                    <label>Input Amount<b className='text-danger'>*</b></label>

                                    <div className='d-flex' style={{ placeContent: "center", width: "75vw", margin: "0 auto", borderBottom: "1.5px solid #00abbdFF" }}>
                                        <p className='kshP'>Ksh</p>
                                        <input value={donationAmount.toLocaleString()} onChange={(e) => setDonationAmount(e.target.value)} style={{ paddingLeft: "25rem", backgroundColor: "transparent", border: "0", outline: "0" }} />

                                    </div>

                                    <div className='d-flex flex-wrap'>
                                        {[50, 100, 500, 1000, 2500, 5000].map((amount, index) => (

                                            <Button
                                                key={index}
                                                className={`btn btn-sm custom-button p-2 me-2 ${activeIndex === index ? 'btn-danger text-white active' : 'bg-secondary bg-opacity-25 border-secondary text-dark'}`}
                                                onClick={() => handleButtonClick(index, amount)}
                                                onChange={handleAmountChange}

                                            >
                                                Ksh. {amount.toLocaleString()}
                                            </Button>
                                        ))}
                                    </div>
                                    <label>M-PESA Phone Number<b className='text-danger'>*</b></label>
                                    <input className='input-field fieldData' type='text'
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        placeholder='Phone Number' style={{ fontSize: "13px" }} />

                                    <div className=''>

                                        {/* <label className='mb-2'>Select Other Phone Numbers</label><br></br> */}
                                        {phoneNumber === userPhoneNumber ? (
                                            <></>
                                        ) : (
                                            !phoneNumberOther.some(entry => entry.phone_number === phoneNumber) && /^(\+254|254|0)\d{9}$/.test(phoneNumber) ? (
                                                <Button className='btn btn-sm text-danger fieldData bg-transparent border-danger' style={{ height: "6vh", marginTop: "-0.5rem" }} onClick={handleShowModalNumber}>
                                                    Add to my numbers
                                                </Button>
                                            ) : (
                                                phoneNumberOther.length > 0 ? (
                                                    <div style={{ position: 'relative' }}>
                                                        {!showOptions ? (
                                                            <Button className='btn btn-sm fieldData text-danger bg-transparent border-danger' style={{ height: "6vh", marginTop: "-0.5rem" }} onClick={handleShowOptions}>
                                                                Choose from my numbers
                                                            </Button>
                                                        ) : (
                                                            <select className="form-select" onChange={(e) => handleSelectPhoneNumber(e.target.value)}>
                                                                <option value="">Select a number</option>
                                                                {phoneNumberOptions}
                                                            </select>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            )
                                        )}
                                    </div>





                                </div>
                            </>
                        )}
                        {selectedPaymentMethod === 'CHEQUE' && (
                            // Content for CHEQUE
                            <>
                                <div className='donation p-0'>
                                    <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Input Amount <b className='text-danger'>*</b></p>

                                    <div className='d-flex' style={{ placeContent: "center", width: "75vw", margin: "0 auto", borderBottom: "1.5px solid #00abbdFF" }}>
                                        <select value={chequeData.currency}
                                            onChange={handlePledgeInput}
                                            className='currency'
                                            style={{ border: "0", color: "#00abbdFF" }}
                                            name='currency'>
                                            <option className='kshP' value='KSH'>KSH</option>
                                            <option className='kshP' value='USD'>USD</option>
                                            <option className='kshP' value='EUR'>EUR</option>
                                            <option className='kshP' value='GBP'>GBP</option>
                                        </select>
                                        <input value={donationAmount.toLocaleString()} onChange={(e) => setDonationAmount(e.target.value)} style={{ paddingLeft: "0rem", backgroundColor: "transparent", border: "0", outline: "0", width: "60vw" }} />

                                    </div>

                                    <div className='d-flex flex-wrap'>
                                        {[50, 100, 500, 1000, 2500, 5000].map((amount, index) => (

                                            <Button
                                                key={index}
                                                className={`btn btn-sm custom-button p-2 me-2 ${activeIndex === index ? 'btn-danger text-white active' : 'bg-secondary bg-opacity-25 border-secondary text-dark'}`}
                                                onClick={() => handleButtonClick(index, amount)}
                                                onChange={handleAmountChange}

                                            >
                                                Ksh. {amount.toLocaleString()}
                                            </Button>
                                        ))}
                                    </div>


                                </div>


                                <p className='fw-bold' style={{ fontSize: "12px" }}>Cheque Number <b className='text-danger'>*</b></p>
                                <input
                                    className='chequeNumber'
                                    style={{ width: "50vw", fontSize: "12px", padding: "0" }}
                                    type='text'
                                    placeholder='Cheque Number'
                                    onChange={handlePledgeInput}
                                    name='chequeNumber'
                                />

                                <p className='fw-bold' style={{ fontSize: "12px" }}>Image Of Cheque(optional)</p>
                                <Button style={{ fontSize: "14px" }} className='btn btn-sm bg-transparent text-danger border-danger' onClick={() => document.getElementById('imageUpload').click()}>Add cheque photo</Button>

                                <input
                                    type="file"
                                    id="imageUpload"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleChangeImage}
                                />
                                {images && images instanceof File && (
                                    <>
                                        <img
                                            src={URL.createObjectURL(images)}
                                            alt=""
                                            className=""
                                            style={{ width: '20vw', height: '20vh', margin: "0 auto", display: "block" }}
                                        />

                                    </>
                                )}


                                <div>
                                    <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Date <b className='text-danger'>*</b></p>
                                    <input
                                        className='chequeNumber'
                                        onChange={handlePledgeInput}
                                        value={chequeData.transaction_date}
                                        type='date'
                                        name='transaction_date'
                                        placeholder='Payment Date'
                                        max={new Date().toISOString().slice(0, 10)}
                                        style={{ width: "100vw", fontSize: "12px", padding: "0" }}

                                    />

                                </div>

                            </>
                        )}
                        {selectedPaymentMethod === 'CARD' && (
                            // Content for CARD
                            <>
                                <div className='donation p-0'>
                                    <p className='fw-bold' style={{ fontSize: "12px" }}>Input Amount <b className='text-danger'>*</b></p>

                                    <div className='d-flex' style={{ placeContent: "center", width: "70vw", margin: "0 auto", borderBottom: "1.5px solid #00abbdFF" }}>
                                        <select value={chequeData.currency}
                                            onChange={handlePledgeInput}
                                            className='currency'
                                            style={{ border: "0", color: "#00abbdFF" }}
                                            name='currency'>
                                            <option className='kshP' value='KSH'>KSH</option>
                                            <option className='kshP' value='USD'>USD</option>
                                            <option className='kshP' value='EUR'>EUR</option>
                                            <option className='kshP' value='GBP'>GBP</option>
                                        </select>
                                        <input value={donationAmount.toLocaleString()} onChange={(e) => setDonationAmount(e.target.value)} style={{ paddingLeft: "0rem", backgroundColor: "transparent", border: "0", outline: "0", width: "55vw" }} />

                                    </div>

                                    <div className='d-flex flex-wrap'>
                                        {[50, 100, 500, 1000, 2500, 5000].map((amount, index) => (

                                            <Button
                                                key={index}
                                                className={`btn btn-sm custom-button p-2 me-2 ${activeIndex === index ? 'btn-danger text-white active' : 'bg-secondary bg-opacity-25 border-secondary text-dark'}`}
                                                onClick={() => handleButtonClick(index, amount)}
                                                onChange={handleAmountChange}

                                            >
                                                Ksh. {amount.toLocaleString()}
                                            </Button>
                                        ))}
                                    </div>


                                </div>


                                <p className='fw-bold' style={{ fontSize: "12px" }}>Reference Number <b className='text-danger'>*</b></p>
                                <input
                                    className='chequeNumber'
                                    style={{ width: "50vw", fontSize: "12px", padding: "0" }}
                                    type='text'
                                    placeholder='Reference Number'
                                    onChange={handlePledgeInput}
                                    name='chequeNumber'
                                />




                                <div>
                                    <p className='fw-bold' style={{ fontSize: "12px" }}>Date <b className='text-danger'>*</b></p>
                                    <input
                                        className='chequeNumber'
                                        onChange={handlePledgeInput}
                                        value={chequeData.transaction_date}
                                        type='date'
                                        name='transaction_date'
                                        placeholder='Payment Date'
                                        max={new Date().toISOString().slice(0, 10)}
                                        style={{ width: "100vw", fontSize: "12px", padding: "0" }}

                                    />

                                </div>

                            </>

                        )}
                        {selectedPaymentMethod === 'PAYPAL' && (
                            // Content for PAYPAL
                            <p className='text-center mt-3 text-danger fw-bold' style={{ fontSize: "20px" }}>COMING SOON</p>
                        )}
                        {selectedPaymentMethod === 'BANK TRANSFER' && (
                            // Content for BANK TRANSFER
                            <>
                                <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Select type of bank transer <b className='text-danger'>*</b></p>

                                {/* <div className='mt-3 d-flex justify-content-between'>

                                    <div className={'ModeofPayment' + (bankTransferOption === 'PESALINK' ? ' selectedPayment' : '')} onClick={() => handleBankTransferOptionChange('PESALINK')}>
                                        <Image src={Bank} style={{ width: "12%" }} />
                                        <p>Pesalink</p>
                                    </div>
                                    <div className={'ModeofPayment' + (bankTransferOption === 'EFT' ? ' selectedPayment' : '')} onClick={() => handleBankTransferOptionChange('EFT')}>
                                        <Image src={Bank} style={{ width: "12%" }} />
                                        <p>EFT</p>
                                    </div>
                                    <div className={'ModeofPayment' + (bankTransferOption === 'RTGS' ? ' selectedPayment' : '')} onClick={() => handleBankTransferOptionChange('RTGS')}>
                                        <Image src={Bank} style={{ width: "12%" }} />
                                        <p>RTGS</p>
                                    </div>

                                </div> */}
                                <div className='mt-3'>
                                    <select
                                        className='form-select w-50'
                                        value={bankTransferOption}
                                        onChange={(e) => handleBankTransferOptionChange(e.target.value)}
                                        style={{ fontSize: "12px" }}
                                    >
                                        <option value='PESALINK'>Pesalink</option>
                                        <option value='EFT'>EFT</option>
                                        <option value='RTGS'>RTGS</option>
                                    </select>
                                </div>

                                <>
                                    <div className='donation p-0'>
                                        <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Input Amount <b className='text-danger'>*</b></p>

                                        <div className='d-flex' style={{ placeContent: "center", width: "75vw", margin: "0 auto", borderBottom: "1.5px solid #00abbdFF" }}>
                                            <select value={chequeData.currency}
                                                onChange={handlePledgeInput}
                                                className='currency'
                                                style={{ border: "0", color: "#00abbdFF" }}
                                                name='currency'>
                                                <option className='kshP' value='KSH'>KSH</option>
                                                <option className='kshP' value='USD'>USD</option>
                                                <option className='kshP' value='EUR'>EUR</option>
                                                <option className='kshP' value='GBP'>GBP</option>
                                            </select>
                                            <input value={donationAmount.toLocaleString()} onChange={(e) => setDonationAmount(e.target.value)} style={{ paddingLeft: "0rem", backgroundColor: "transparent", border: "0", outline: "0", width: "60vw" }} />

                                        </div>

                                        <div className='d-flex flex-wrap'>
                                            {[50, 100, 500, 1000, 2500, 5000].map((amount, index) => (

                                                <Button
                                                    key={index}
                                                    className={`btn btn-sm custom-button p-2 me-2 ${activeIndex === index ? 'btn-danger text-white active' : 'bg-secondary bg-opacity-25 border-secondary text-dark'}`}
                                                    onClick={() => handleButtonClick(index, amount)}
                                                    onChange={handleAmountChange}

                                                >
                                                    Ksh. {amount.toLocaleString()}
                                                </Button>
                                            ))}
                                        </div>


                                    </div>


                                    <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Bank <span className='text-danger'>*</span></p>
                                    <Select
                                        options={optionList}
                                        placeholder="Select a bank"
                                        value={selectedBank}
                                        onChange={handleSelectChange}
                                        style={{ padding: "0", fontSize: "10px" }}
                                        required
                                    />

                                    <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Transaction Number <b className='text-danger'>*</b></p>
                                    <input
                                        className='chequeNumber'
                                        style={{ width: "35vw", padding: "0", fontSize: "12px" }}
                                        type='text'
                                        placeholder='Transaction Number'
                                        onChange={handlePledgeInput}
                                        value={chequeData.chequeNumber}

                                        name='chequeNumber'
                                    />

                                    <div>
                                        <p className='fw-bold' style={{ fontSize: "12px" }}>Date <b className='text-danger'>*</b></p>
                                        <input
                                            className='chequeNumber'
                                            onChange={handlePledgeInput}
                                            value={chequeData.transaction_date}
                                            type='date'
                                            name='transaction_date'
                                            placeholder='Payment Date'
                                            max={new Date().toISOString().slice(0, 10)}
                                            style={{ padding: "0", fontSize: "12px" }}
                                        />

                                    </div>

                                </>
                            </>

                        )}


                        {selectedPaymentMethod === 'PAYPAL' || selectedPaymentMethod === null ? null :
                            selectedPaymentMethod && (
                                <>
                                    <p onClick={handleShow} className='text-info mt-3 d-none' style={{ textTransform: "capitalize", fontSize: "11px", marginInline: "3rem" }}>
                                        Tap to share a link to another person to contribute for you
                                    </p>
                                    {selectedPaymentMethod === 'MPESA' ? (
                                        <div style={{ borderTop: "2px solid grey", marginBottom: "5rem" }}>
                                            <p className='text-danger fw-bold mt-2'>Alternative M-PESA Payment Steps:</p>
                                            <ol>
                                                <li>Launch SIM Toolkit or M-PESA App</li>
                                                <li>Select Paybill</li>
                                                <li>Enter the Business Number: <b>764705</b></li>
                                                <li>Enter the Account Number: <b>{id}</b></li>
                                                <li>Enter Amount and complete the transaction using your M-PESA PIN</li>
                                            </ol>
                                        </div>
                                    ) : selectedPaymentMethod === 'BANK TRANSFER' || selectedPaymentMethod === 'CHEQUE' ?
                                        <div style={{ borderTop: "2px solid grey", marginBottom: "5rem" }}>
                                            {/* <p className='text-danger fw-bold mt-2'>Alternative Bank transfer Payment Steps:</p>
                                            <ol>
                                                <li>Bank: KCB Bank</li>
                                                <li>Branch: Karen Branch</li>
                                                <li>Account Name: CAPSTONE FAITH MINISTRY</li>
                                                <li>Account Number: 1236697723</li>
                                            </ol> */}
                                        </div>
                                        :


                                        (
                                            <>
                                            </>
                                        )}
                                </>
                            )
                        }


                    </>



                {/* modal for sharing */}
                <Modal size="lg" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title className='text-danger fw-bold m-auto'>SHARE THIS PLEDGE</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="d-flex">
                            <div
                                className="text-decoration-none text-dark w-50"
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => handlePlatform("whatsapp")}
                            >
                                <Col
                                    md={6}
                                    className="d-flex shareButtons"
                                    style={{
                                        border: "1px solid #00abbdFF",
                                        width: "98%",
                                        marginInline: "0.5rem",
                                        marginTop: "1rem",
                                        borderRadius: "10px",
                                    }}
                                    onMouseEnter={(e) =>
                                        (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                                    }
                                    onMouseLeave={(e) =>
                                        (e.currentTarget.style.backgroundColor = "inherit")
                                    }
                                >
                                    <Image
                                        src={Whatsapp}
                                        className="mb-2 me-2 mt-2 ms-2"
                                        style={{
                                            borderRadius: "10px",
                                            width: "auto",
                                            height: "5vh",
                                        }}
                                    />
                                    <p className="mt-3">Share on WhatsApp</p>
                                </Col>
                            </div>

                            <div
                                className="text-decoration-none text-dark w-50"
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => handlePlatform("facebook")}
                            >
                                <Col
                                    md={6}
                                    className="d-flex shareButtons"
                                    style={{
                                        border: "1px solid #00abbdFF",
                                        width: "91%",
                                        marginInline: "0.5rem",
                                        marginTop: "1rem",
                                        borderRadius: "10px",
                                        cursor: "pointer",
                                    }}
                                    onMouseEnter={(e) =>
                                        (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                                    }
                                    onMouseLeave={(e) =>
                                        (e.currentTarget.style.backgroundColor = "inherit")
                                    }
                                    onClick={() => shareFacebook()}
                                >
                                    <Image
                                        src={Facebook}
                                        className="mb-2 me-2 mt-2 ms-2"
                                        style={{
                                            borderRadius: "10px",
                                            width: "auto",
                                            height: "5vh",
                                        }}
                                    />
                                    <p className="mt-3">Share on Facebook</p>
                                </Col>
                            </div>
                        </Row>

                        <Row className="d-flex">
                            <div
                                className="text-decoration-none text-dark w-50"
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => handlePlatform("gmail")}
                            >
                                <Col
                                    md={6}
                                    className="d-flex shareButtons"
                                    style={{
                                        border: "1px solid #00abbdFF",
                                        width: "91%",
                                        marginInline: "0.5rem",
                                        marginTop: "1rem",
                                        borderRadius: "10px",
                                        cursor: "pointer",
                                    }}
                                    onMouseEnter={(e) =>
                                        (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                                    }
                                    onMouseLeave={(e) =>
                                        (e.currentTarget.style.backgroundColor = "inherit")
                                    }
                                >
                                    <Image
                                        src={Mail}
                                        className="mb-2 me-2 mt-2 ms-2"
                                        style={{
                                            borderRadius: "10px",
                                            width: "auto",
                                            height: "5vh",
                                        }}
                                    />
                                    <p className="mt-3">Share on Gmail</p>
                                </Col>
                            </div>

                            <div
                                className="text-decoration-none text-dark w-50"
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => handlePlatform("x")}
                            >
                                <Col
                                    md={6}
                                    className="d-flex shareButtons"
                                    style={{
                                        border: "1px solid #00abbdFF",
                                        width: "91%",
                                        marginInline: "0.5rem",
                                        marginTop: "1rem",
                                        borderRadius: "10px",
                                        cursor: "pointer",
                                    }}
                                    onMouseEnter={(e) =>
                                        (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                                    }
                                    onMouseLeave={(e) =>
                                        (e.currentTarget.style.backgroundColor = "inherit")
                                    }
                                >
                                    <Image
                                        src={Twitter}
                                        className="mb-2 me-2 mt-2 ms-2"
                                        style={{
                                            borderRadius: "10px",
                                            width: "auto",
                                            height: "5vh",
                                        }}
                                    />
                                    <p className="mt-3">Share on Twitter</p>
                                </Col>
                            </div>
                        </Row>

                        <Row className="d-flex">
                            <div
                                className="text-decoration-none text-dark w-50"
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => handlePlatform("instagram")}
                            >
                                <Col
                                    md={6}
                                    className="d-flex shareButtons"
                                    style={{
                                        border: "1px solid #00abbdFF",
                                        width: "91%",
                                        marginInline: "0.5rem",
                                        marginTop: "1rem",
                                        borderRadius: "10px",
                                        cursor: "pointer",
                                    }}
                                    onMouseEnter={(e) =>
                                        (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                                    }
                                    onMouseLeave={(e) =>
                                        (e.currentTarget.style.backgroundColor = "inherit")
                                    }
                                >
                                    <Image
                                        src={Instagram}
                                        className="mb-2 me-2 mt-2 ms-2"
                                        style={{
                                            borderRadius: "10px",
                                            width: "auto",
                                            height: "5vh",
                                        }}
                                    />
                                    <p className="mt-3">Share on Instagram</p>
                                </Col>
                            </div>

                            <Col
                                className='shareButtons'
                                md={6}
                                style={{
                                    border: "1px solid #00abbdFF",
                                    width: "44%",
                                    marginInline: "1.1rem",
                                    marginTop: "1rem",
                                    borderRadius: "10px",
                                    cursor: "pointer",
                                }}
                                onMouseEnter={(e) =>
                                    (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                                }
                                onMouseLeave={(e) =>
                                    (e.currentTarget.style.backgroundColor = "inherit")
                                }
                                onClick={() => handlePlatform("copy_link")}
                            >
                                <div className="d-flex">
                                    <Image
                                        src={Paperclip}
                                        className="mb-2 me-2 mt-2"
                                        style={{
                                            borderRadius: "10px",
                                            width: "auto",
                                            height: "5vh",
                                        }}
                                    />
                                    <p className="mt-3">Copy the link </p>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <div className='footerButton'>
                    {payLoading >
                        <Button className='btn-danger'>
                            <Spinner />
                        </Button>
                    }
                    <Button className='btn-danger' onClick={handleRedeemAdd}>Redeem</Button>
                </div>


                {/* add phone number */}
                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title >
                            <h5 className='text-danger'>Confirm Number Addition</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <p>Are you sure you want to add the number {phoneNumber} to your numbers?            </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            Cancel
                        </Button>
                        {isLoading ?
                            <Button variant="danger">
                                <Spinner />
                            </Button>
                            :
                            <Button variant="danger" className='border-0' onClick={handleAddNumber}>
                                Confirm
                            </Button>}

                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
}

export default Donation;
